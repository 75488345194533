<template>
  <div class="footer">
    <div class="content">
      <p class="text-footer">© 2021 – {{year}} Virtu. All Rights Reserved.</p>
      <p class="text-footer-desc">
        Virtu is part of
        <b> <a href="https://www.biznetnetworks.com/"> Biznet.</a></b>
        <b> <a href="https://www.biznetnetworks.com/"> Biznet</a></b> is part of
        <b> <a href="https://www.midplaza.com/"> Midplaza Holding. </a></b>
      </p>
      <p class="text-footer-desc">From Bali to The World.</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';


export default {
  data:()=>({
    year:''
  }),
  mounted(){
    this.year = moment().format('YYYY')
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/color.scss";
.footer {
  min-height: 110px;
  background: $main_2;
  position: relative !important;
  width: 100%;
  background: $main_2;
  background-clip: content-box;
  clear: both;
  text-align: center;
  // right: 0;
  // bottom: -110px;

  padding-top: 0.65rem;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: calc(50% - (-1px));
    height: 0.7rem;
    background: $main_2;
    left: 0;
    width: 75%;
    transform: skew(45deg);
    transform-origin: right bottom;
    border-top-right-radius: 1rem;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: calc(50% - (-1px));
    height: 0.7rem;
    background: $main_2;
    right: -25%;
    transform: skew(-45deg);
    transform-origin: left bottom;
    border-top-left-radius: 1rem;
  }
}

.text-footer {
  color: white;
  font-size: 12px;
  font-weight: bold;
  a {
    color: #1f3c87;
    text-decoration: none;
  }
  &::after{
    margin-right: 10px;
  }
}

.text-footer-desc {
  color: white;
  font-size: 12px;
  margin-top: -15px;
  a {
    color: #1f3c87;
    text-decoration: none;
  }
}

.divider {
  border-color: whitesmoke;
  margin-top: 10px;
  margin-bottom: 5px;
}

.content {
  margin-top: 20px;
}

</style>

<template>
  <div class="box-menu">
    <div class="d-none d-md-flex flex-grow-1 align-center pl-3">
      <tab 
      :hideTitle="hideTitle"
      />
      <topbar />
    </div>

    <item-menu
      :item="item"
      v-for="(item, index) of menu"
      :key="index"
      :active="
        item.path == '/patient' ||
        item.path == '/doctor' ||
        item.path == '/medical-center' ||
        item.path == '/company'
          ? $route.path.includes(`/${$i18n.locale}`)
            ? $route.path == `/${$i18n.locale}${item.path}`
            : $route.path == item.path
          : $route.path.includes(item.path)
      "
      :hideTitle="hideTitle"
    />
  </div>
</template>

<script>
import ItemMenu from "./item_menu.vue";
import Tab from "@/components/v2/switch_account/tab.vue";
import Topbar from "@/components/v2/topbar/index.vue";

export default {
  components: { ItemMenu,Tab,Topbar },
  props: {
    hideTitle: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/color.scss";
.box-menu {
  border-bottom: 1px solid $gray_2;
  @media screen and (max-width: 960px) {
    flex-direction: row;
    border-bottom: none;
    width: 100%;
    justify-content: flex-start;
    display: flex;
    border-right: 1px solid $gray_2;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-menu"},[_c('div',{staticClass:"d-none d-md-flex flex-grow-1 align-center pl-3"},[_c('tab',{attrs:{"hideTitle":_vm.hideTitle}}),_c('topbar')],1),_vm._l((_vm.menu),function(item,index){return _c('item-menu',{key:index,attrs:{"item":item,"active":item.path == '/patient' ||
      item.path == '/doctor' ||
      item.path == '/medical-center' ||
      item.path == '/company'
        ? _vm.$route.path.includes(`/${_vm.$i18n.locale}`)
          ? _vm.$route.path == `/${_vm.$i18n.locale}${item.path}`
          : _vm.$route.path == item.path
        : _vm.$route.path.includes(item.path),"hideTitle":_vm.hideTitle}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
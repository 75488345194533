<template>
  <div
    class="sidebar"
    :class="{
      'sidebar--collapse': collapse || width < 1264,
      'sidebar--patient': userType == 1,
      'sidebar--doctor': userType == 2,
      'sidebar--company': userType == 3,
      'sidebar--company': userType == 4,
      'sidebar--company': userType == 5,
      'sidebar--doctor': userType == 6,
    }"
  >
    <div class="sidebar__header">
      <!-- <h4 v-show="userType !== 1">
        <span class="sidebar__header__text">User </span>Profile
      </h4> 
      <template v-if="userType == 1">
        -->
      <template>
        <img
          src="/iconVirtu.png"
          v-show="!collapse && width > 1264"
          alt="Icon Virtu"
        />
        <img
          src="/iconVirtuMini.png"
          v-show="collapse || width < 1264"
          alt="Icon Virtu"
        />
      </template>
    </div>
    <div class="sidebar__body">
      <user-card
        v-if="profile != null"
        :hideDetail="collapse || width < 1264"
        :type="userType"
        :profile="profile"
        @click="$emit('clickProfile')"
      >
      </user-card>
      <Menu
        :hideTitle="collapse || width < 1264"
        :menu="listMenu"
        v-if="listMenu != null"
      />
      <item-menu
        v-if="settingMenu != null"
        :hideTitle="collapse || width < 1264"
        rotateIcon="90"
        :item="settingMenu"
        :active="settingMenu.path == $route.path"
      />
    </div>
    <div class="sidebar__footer" v-if="width >= 1264">
      <item-menu
        :hideTitle="collapse || width < 1264"
        @click="collapse = !collapse"
        :item="{ path: '', name: $t('menu.toggle_sidebar'), icon: 'toggle' }"
      />
    </div>
  </div>
</template>

<script>
import Menu from "./menu.vue";
import UserCard from "./UserCard.vue";
import ItemMenu from "./item_menu.vue";
import Auth from "@/service/auth_service";

export default {
  components: { UserCard, Menu, ItemMenu },
  data: () => ({
    collapse: false,
    profile: null,
    userType: "",
    listMenu: null,
    settingMenu: null,
    contactPrimary: [],
    width: 0,
    verified: false,
  }),
  computed: {
    // get data profile from store
    dataProfile() {
      return this.$store.state.profileData;
    },
    dataMenu(){
      return this.$store.state.menuData;
    }
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },

  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  mounted() {
    this.width = window.innerWidth;

    this.userType = Auth.getTypeUser();
    this.setMenu();
    if (this.dataProfile) {
      this.getProfile();
    }
  },
  methods: {
    titleCase(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
    resizeHandler() {
      this.width = window.innerWidth;
    },
    async getProfile() {
      try {
        let name = "";
        let email = "";
        if (this.userType == 6) {
          this.contactPrimary =
            await this.dataProfile.health_facility.contacts.filter(
              contactGet => {
                return true == contactGet.primary;
              }
            );
          name = this.dataProfile.health_facility.info.company_name;
          email = this.contactPrimary[0].contact_email;
          this.verified = false;
        } else if (
          this.userType == 3 ||
          this.userType == 4 ||
          this.userType == 5
        ) {
          this.contactPrimary = await this.dataProfile.company.contacts.filter(
            contactGet => {
              return true == contactGet.primary;
            }
          );
          name = this.dataProfile.company.info.company_name;
          email = this.contactPrimary[0].contact_email;
          this.verified = false;
        } else if (this.userType == 2) {
          name = this.dataProfile.person.name;
          email = this.dataProfile.person.contacts.email;
          this.verified = false;
        } else {
          name = this.dataProfile.person.personal_info.name;
          email = this.dataProfile.person.personal_info.contacts.email;
          this.verified = this.dataProfile.person.patient.verified;
          // this.verified = true;
        }

        this.profile = {
          email: email,
          name: name,
          verified: this.verified,
          urlImage:
            this.dataProfile.photo_profile_path == null
              ? null
              : `${process.env.VUE_APP_API_TRUST}profile/${this.dataProfile.photo_profile_path}`,
        };
      } catch (error) {
        console.log(error);
      }
    },
    async setMenu() {
      if (this.userType == 1) {
        this.listMenu = [
          {
            name: this.$t("menu.home"),
            path: "/patient",
            icon: "home",
          },
          {
            name: this.$t("menu.book_lab_test"),
            icon: "test",
            path: "/patient/test",
          },
          {
            name: this.$t("menu.book_home_care"),
            icon: "homecare",
            path: "/patient/home-care",
          },
          // {
          //   name: this.$t("menu.my_order"),
          //   icon: "icon-booking",
          //   path: "/patient/my-order",
          // },
          {
            name: this.$t("menu.buy_package"),
            icon: "my-package",
            path: "/patient/buy-package",
          },
          // {
          //   name: this.$t("menu.buy_subscription"),
          //   icon: "my-package",
          //   path: "/patient/buy-subscription",
          // },
          //   name: this.$t("menu.promo"),
          //   icon: "icon-promo",
          //   path: "/patient/promo",
          // },
          {
            name: this.$t("menu.virtulab_nearme"),
            icon: "marker",
            path: "/patient/nearme",
          },
          {
            name: this.$t("menu.account"),
            icon: "user",
            path: "/patient/profile",
          },
          // {
          //   name: this.$t("menu.setting"),
          //   path: "/patient/setting/password",
          //   icon: "icon-gear",
          // },
          {
            name: this.$t("menu.sign_out"),
            path: "/sign-out",
            icon: "sign-out",
          },
        ];
        // this.settingMenu = {
        //   name: "Setting",
        //   path: "/patient/setting/profile",
        //   icon: "setting",
        // };
      } else if (this.userType == 2) {
        this.listMenu = [
          {
            name: this.$t("menu.home"),
            path: "/doctor",
            icon: "home",
          },
          {
            name: this.$t("menu.patient_appointment"),
            path: "/doctor/appointment/patient-list",
            icon: "billing",
          },
          // {
          //   name: this.$t("menu.referal"),
          //   path: "/doctor/referral/confirmation",
          //   icon: "user",
          // },
          {
            name: this.$t("menu.sign_out"),
            path: "/sign-out",
            icon: "sign-out",
          },
        ];
        // this.settingMenu = {
        //   name: "Setting",
        //   path: "/doctor/setting/profile",
        //   icon: "setting",
        // };
      } else if (this.userType == 3) {
        let newVar = [];
        this.dataMenu.forEach(element => {
          var capitalizeFinal = this.titleCase(element.name);
          newVar = [
            ...newVar,
            { name: capitalizeFinal, path: element.link, icon: element.icon },
          ];
        });

        this.listMenu = newVar;
        // this.listMenu = [
        //   {
        //     name: this.$t("menu.home"),
        //     path: "/company",
        //     icon: "home",
        //   },
        //   {
        //     name: this.$t("menu.master_employee"),
        //     path: "/company/employee/master",
        //     icon: "user-office",
        //   },
        //   {
        //     name: this.$t("menu.book_office_care"),
        //     icon: "office",
        //     path: "/company/office-care",
        //   },
        //   {
        //     name: this.$t("menu.medical_record"),
        //     icon: "billing",
        //     path: "/company/medical-record",
        //   },
        //   {
        //     name: this.$t("tab.deposit_trans"),
        //     icon: "transaction",
        //     path: "/company/deposit-transaction",
        //   },

        //   {
        //     name: this.$t("menu.sign_out"),
        //     path: "/sign-out",
        //     icon: "sign-out",
        //   },
        // ];
      }
      // else if (this.userType == 4) {
      //   const resp = await API.get(
      //     `${process.env.VUE_APP_API_TRUST}get-menu?lang=${this.$i18n.locale}`,
      //     {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${Auth.getToken()}`,
      //     }
      //   );

      //   let newVar = [];
      //   resp.results.forEach((element) => {
      //       newVar = [...newVar, { name: element.name, path: element.link, icon: element.icon}]
      //   })
      //   this.listMenu = newVar;
      //   // this.listMenu = [
      //   //   {
      //   //     name: this.$t("menu.home"),
      //   //     path: "/company",
      //   //     icon: "home",
      //   //   },
      //   //   {
      //   //     name: this.$t("menu.examination_approve"),
      //   //     icon: "billing",
      //   //     path: "/company/examination-approval",
      //   //   },
      //   //   {
      //   //     name: this.$t("menu.examination_history"),
      //   //     icon: "office",
      //   //     path: "/company/office-care",
      //   //   },
      //   //   {
      //   //     name: this.$t("menu.medical_record"),
      //   //     icon: "billing",
      //   //     path: "/company/medical-record",
      //   //   },
      //   //   {
      //   //     name: this.$t("tab.deposit_trans"),
      //   //     icon: "transaction",
      //   //     path: "/company/deposit-transaction",
      //   //   },
      //   //   {
      //   //     name: this.$t("menu.sign_out"),
      //   //     path: "/sign-out",
      //   //     icon: "sign-out",
      //   //   },
      //   // ];
      // }
      // else if (this.userType == 5) {
      //   const resp = await API.get(
      //     `${process.env.VUE_APP_API_TRUST}get-menu?lang=${this.$i18n.locale}`,
      //     {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${Auth.getToken()}`,
      //     }
      //   );

      //   let newVar = [];
      //   resp.results.forEach((element) => {
      //       newVar = [...newVar, { name: element.name, path: element.link, icon: element.icon}]
      //   })
      //   this.listMenu = newVar;
      //   // this.listMenu = [
      //   //   {
      //   //     name: this.$t("menu.home"),
      //   //     path: "/company",
      //   //     icon: "home",
      //   //   },
      //   //   {
      //   //     name: this.$t("menu.examination_approve"),
      //   //     icon: "billing",
      //   //     path: "/company/examination-approval",
      //   //   },
      //   //   {
      //   //     name: this.$t("menu.examination_history"),
      //   //     icon: "office",
      //   //     path: "/company/office-care",
      //   //   },
      //   //   {
      //   //     name: this.$t("menu.medical_record"),
      //   //     icon: "billing",
      //   //     path: "/company/medical-record",
      //   //   },
      //   //   {
      //   //     name: this.$t("tab.deposit_trans"),
      //   //     icon: "transaction",
      //   //     path: "/company/deposit-transaction",
      //   //   },

      //   //   {
      //   //     name: this.$t("menu.sign_out"),
      //   //     path: "/sign-out",
      //   //     icon: "sign-out",
      //   //   },
      //   // ];

      // }
      else if (this.userType == 6) {
        this.listMenu = [
          {
            name: this.$t("menu.home"),
            path: "/medical-center",
            icon: "home",
          },
          {
            name: this.$t("menu.medical_record"),
            path: "/medical-center/mr-patient",
            icon: "billing",
          },
          // {
          //   name: "Summary Reports",
          //   path: "/doctor/summary-report/referral",
          //   icon: "summary-reports",
          // },
          // {
          //   name: this.$t("menu.referal"),
          //   path: "/doctor/referral/confirmation",
          //   icon: "user",
          // },
          {
            name: this.$t("menu.sign_out"),
            path: "/sign-out",
            icon: "sign-out",
          },
        ];
        // this.settingMenu = {
        //   name: "Setting",
        //   path: "/doctor/setting/profile",
        //   icon: "setting",
        // };
      } else {
        this.listMenu = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/color.scss";
.sidebar {
  width: 256px;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  @media screen and (max-width: 960px) {
    width: 100%;
    .sidebar {
      &__header {
        &__text {
          display: none;
        }
      }
    }
  }
  &.sidebar--collapse {
    width: auto;
    .sidebar {
      &__header {
        &__text {
          display: none;
        }
      }
    }
  }
  &.sidebar--patient {
    display: flex;
    @media screen and (max-width: 960px) {
      display: none;
    }
  }
  &__header {
    padding: 20px 24px;
    border-bottom: 1px solid $gray_2;
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: $main_2;
    }
    img {
      height: 40px;
      width: auto;
    }
    @media screen and (max-width: 960px) {
      display: none;
    }
  }

  &__body {
    flex: 1;
    overflow: auto;
    @media screen and (max-width: 960px) {
      display: flex;
      overflow: inherit;
    }
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
  }
  &__footer {
    @media screen and (max-width: 960px) {
      display: none;
    }
  }
}

@media screen and (max-width: 900px) {
  .sidebar--company {
    display: none;
  }
  .sidebar--doctor {
    display: none;
  }
}
</style>

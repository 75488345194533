var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar",class:{
    'sidebar--collapse': _vm.collapse || _vm.width < 1264,
    'sidebar--patient': _vm.userType == 1,
    'sidebar--doctor': _vm.userType == 2,
    'sidebar--company': _vm.userType == 3,
    'sidebar--company': _vm.userType == 4,
    'sidebar--company': _vm.userType == 5,
    'sidebar--doctor': _vm.userType == 6,
  }},[_c('div',{staticClass:"sidebar__header"},[[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapse && _vm.width > 1264),expression:"!collapse && width > 1264"}],attrs:{"src":"/iconVirtu.png","alt":"Icon Virtu"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.collapse || _vm.width < 1264),expression:"collapse || width < 1264"}],attrs:{"src":"/iconVirtuMini.png","alt":"Icon Virtu"}})]],2),_c('div',{staticClass:"sidebar__body"},[(_vm.profile != null)?_c('user-card',{attrs:{"hideDetail":_vm.collapse || _vm.width < 1264,"type":_vm.userType,"profile":_vm.profile},on:{"click":function($event){return _vm.$emit('clickProfile')}}}):_vm._e(),(_vm.listMenu != null)?_c('Menu',{attrs:{"hideTitle":_vm.collapse || _vm.width < 1264,"menu":_vm.listMenu}}):_vm._e(),(_vm.settingMenu != null)?_c('item-menu',{attrs:{"hideTitle":_vm.collapse || _vm.width < 1264,"rotateIcon":"90","item":_vm.settingMenu,"active":_vm.settingMenu.path == _vm.$route.path}}):_vm._e()],1),(_vm.width >= 1264)?_c('div',{staticClass:"sidebar__footer"},[_c('item-menu',{attrs:{"hideTitle":_vm.collapse || _vm.width < 1264,"item":{ path: '', name: _vm.$t('menu.toggle_sidebar'), icon: 'toggle' }},on:{"click":function($event){_vm.collapse = !_vm.collapse}}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="menu">
    <div
      class="menu__item"
      :class="{
        'menu__item--active': active,
        'menu__item--collapse': hideTitle,
      }"
      @click="$emit('click')"
    >
      <localized-link :to="item.path" v-if="item.path != ''">
        
        <div v-if="item.icon.substring(0, 4) == '<svg'">
          <div v-html="item.icon" class="menu__icon" :class="{ 'rotate-90': rotateIcon == 90 }" :color="active ? '#1F3C87' : '#00a4b3'"></div>
        </div>
        <icons v-else
          :icon="item.icon"
          class="menu__icon"
          :class="{ 'rotate-90': rotateIcon == 90 }"
          :color="active ? '#1F3C87' : '#00a4b3'"
        />
        <span>
          {{ item.name }}
        </span>
      </localized-link>
      <router-link v-else
      :disabled="!whateverActivatesThisLink" 
      :event="whateverActivatesThisLink ? 'click' : ''"
      :to="item.path">
        <!-- <icons
          :icon="item.icon"
          class="menu__icon"
          :class="{ 'rotate-90': rotateIcon == 90 }"
          :color="active ? '#1F3C87' : '#00a4b3'"
        /> -->
        <div v-if="item.icon.substring(0, 4) == '<svg'">
          <div v-html="item.icon" class="menu__icon" :class="{ 'rotate-90': rotateIcon == 90 }" :color="active ? '#1F3C87' : '#00a4b3'"></div>
        </div>
        <icons v-else
          :icon="item.icon"
          class="menu__icon"
          :class="{ 'rotate-90': rotateIcon == 90 }"
          :color="active ? '#1F3C87' : '#00a4b3'"
        />
        <span>
          {{ item.name }}
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
import icons from "@/components/elements/Icons.vue";
export default {
  components: { icons },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    active: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    rotateIcon: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    whateverActivatesThisLink: '',
  }),
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/color.scss";
.menu {
  @media screen and (max-width: 960px) {
    display: flex;
  }
  &__item {
    padding: 8px 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    @media screen and (max-width: 960px) {
      padding: 12px 14px;
    }
    @media screen and (max-width: 600px) {
      padding: 8px;
    }

    a {
      text-decoration: none;
      color: $main_2;
      display: flex;
    }
    &--active {
      a {
        color: $dark_blue_2;
        font-weight: bold;
      }
    }
    &--collapse {
      justify-content: center;
      .menu__icon {
        margin: 0;
      }
      span {
        display: none;
      }
    }
    @media screen and (max-width: 960px) {
      justify-content: center;
      .menu__icon {
        margin: 0;
        &.rotate-90 {
          transform: rotate(90deg);
        }
      }
      span {
        display: none;
      }
    }
  }
  &__icon {
    margin-right: 14px;
    display: flex;
    align-items: center;
    width: 20px;
  }
}
</style>
